import { decode } from 'he';

// Generate url slug, ä -> ae, ö -> oe, ü -> ue, ß -> ss
export const generateUrlSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/[^a-z0-9]+/g, '-');
};

/*
@param text: string
@returns string - text with all HTML tags removed
*/
export const removeHTMLTags = (text: string): string => {
  return decode(text.replace(/<(?:.|\n)*?>/gm, ''));
};
